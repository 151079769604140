Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.apiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "razorpayintegration";
exports.labelBodyText = "razorpayintegration Body";

exports.btnExampleTitle = "CLICK ME";
exports.labelTotalFees = "Total Fees:  ";
exports.labelTotalItems = "Total Items:  ";
exports.labelTotalTax = "Total Tax:  ";
exports.labelCustomer = "Customer:  ";
exports.btnCheckout = "Checkout";
exports.msgPaymentSuccess = "Payment was successfully completed";
exports.msgPaymentFailed = "Payment was not completed";

exports.razorPayTestKey = "rzp_test_Feaa8XmuED2hRE";
exports.razorPayLiveKey = "rzp_test_Feaa8XmuED2hRE";
exports.postApiMethod = "POST";
exports.putApiMethod = "PUT";
exports.createOrderApiEndPoint = "bx_block_razorpay/create_order/";
exports.getOrdersApiEndPoint = "bx_block_shopping_cart/orders";
exports.putOrdersUpdateApiEndPoint =
  "bx_block_razorpay/verify_payment_signature";

exports.getApiMethod = "GET";
exports.deleteApiMethod = "DELETE";

exports.createOrderItemApiEndPoint = "bx_block_shopping_cart/order_items";
exports.createObjectApiEndPoint =
  "bx_block_ccavenueintegration2/check_out_encrypt_data";
exports.checkOutDecryptDataEndPoint =
  "bx_block_ccavenueintegration2/check_out_decrypt_data";
exports.verifyRazorPaymentEndPoint =
  "bx_block_razorpay/verify_payment_signature";

exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";
exports.successText = "Congratulations! payment completed successfully";
exports.alertMessageText = "Oops! something went wrong";
exports.catalogueIdText = "Catalogue Id";
exports.orderIdText = "Order Id";
exports.currencyText = "Currency";
exports.catalogueText = "Catalogue";
exports.quantityText = "Quantity";
exports.taxableText = "Taxable";
exports.taxableValueText = "Taxable Value";
exports.noOrdersToDisplay = "No orders to display !";
exports.idText = "Id";
exports.priceText = "Price";
exports.actionText = "Action";
exports.totalFeesText = "Total Fees";
exports.totalItemsText = "Total Items";
exports.totalTaxText = "Total Tax";
exports.customerText = "Customer";
exports.amountText = "Amount";
exports.parameterValueText = "Parameter Value";
exports.parameterNameText = "Parameter Name";
exports.compulsoryInformationText = "Compulsory Information";
exports.languageText = "Language";
exports.billingInformation = "Billing Information";
exports.billingAddress = "Billing Address";
exports.billingCity = "Billing City";
exports.billingState = "Billing State";
exports.billingZip = "Billing Zip";
exports.billingNameText = "Billing Name";
exports.billingCountry = "Billing Country";
exports.billingTel = "Billing Tel";
exports.billingEmail = "Billing Email";
exports.shippingInformationText = "Shipping Information";
exports.shippingNameText = "Shipping Name";
exports.shippingAddressText = "Shipping Address";
exports.shippingCityText = "Shipping City";
exports.shippingStateText = "Shipping State";
exports.shippingZipText = "Shipping Zip";
exports.shippingCountryText = "Shipping Country";
exports.shippingTelText = "Shipping Tel";
exports.checkOutButtonText = "Checkout";
exports.viewButtonText = "View";
exports.addButtonText = "Add";
exports.closeButtonText = "Close";
exports.cancelButtonText = "Cancel";
exports.deleteButtonText = "Delete";
exports.viewAllOrdersButtonText = "View all orders";
exports.addOrderItemButtonText = "Add Order Item";
exports.deleteOrderFailedText = "Deleting the order is failed!";
exports.checkoutText = "Checkout Page";

exports.noDataErrorText = "No orders present !";
exports.createRazorApiEndPoint = "bx_block_razorpay/create_order";
exports.createRazorOrderError = "Something went wrong in razorpay!";
exports.razorKey = "rzp_test_Feaa8XmuED2hRE";
exports.projectNameText = "platformmarketplacejp_int";
exports.description = "Test Description";
exports.razorPayNote = "Click here to continue your payment with RazorPay";
exports.prefillName = "User Name";
exports.prefillAddress = "Razorpay Corporate Office";
exports.customerDetailsText = "Customer Details";
exports.checkOutButtonTextTwo = "Proceed to Checkout";
exports.currencyValue = "INR";
exports.themeColor = "#6200EE";
exports.phoneNumberText = "Phone No";
// Customizable Area End
