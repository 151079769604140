import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { Stripe, StripeElements } from '@stripe/stripe-js';
export interface APIPayloadType {
  contentType: string;
  method: string;
  endPoint: string;
  body?: object;
}

export interface RegionType {
  name: string;
  key: string;
}

export interface ValidResponseType {
  stripe_publishable_key: string;
  payment_intent: { client_secret: string };
  connected_account: { id: string };
  message: string;
  account_link: { url: string }
}

export interface InvalidResponseType {
  error: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  stripe: Stripe | null;
  elements: StripeElements | null;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  stripForm: boolean;
  clientSecret: string;
  stripPublicKey: string;
  amount: string;
  currency: RegionType;
  region: RegionType;
  blankAmountError: boolean;
  blankCurrencyError: boolean;
  blankRegionError: boolean;
  isOpenRegion: boolean;
  isOpenCurrency: boolean;
  displayRegion: RegionType[];
  displayCurrency: RegionType[];
  redirectStatus: string;
  email: string;
  emailError: boolean;
  emailErrorMsg: string;
  blankEmailError: boolean;
  merchantId: string;
  urlForOnboarding: string;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class StripeConnectController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  createPaymentIntentApiCallId: string = "";
  getPublicKeyApiCallId: string = "";
  postCreateAccountApiCallId: string = "";
  postAccountOnboardingApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      blankAmountError: false,
      blankCurrencyError: false,
      blankRegionError: false,
      stripForm: false,
      amount: "",
      currency: {
        name: "",
        key: ""
      },
      region: {
        name: "",
        key: ""
      },
      clientSecret: "",
      stripPublicKey: "",
      isOpenRegion: false,
      isOpenCurrency: false,
      displayRegion: [
        { key: 'USD', name: 'US' },
        { key: 'GBP', name: 'UK' }
      ],
      displayCurrency: [
        {
          "name": "USD",
          "key": "USD"
        },
        {
          "name": "GBP",
          "key": "GBP"
        }
      ],
      redirectStatus: "",
      email: "",
      emailError: false,
      emailErrorMsg: "",
      blankEmailError: false,
      merchantId: "",
      urlForOnboarding: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (this.isValidResponse(responseJson)) {
        this.apiSuccessCallBacks(apiRequestCallId, responseJson);
      } else if (this.isInValidResponse(responseJson)) {
        this.apiFailureCallBacks(apiRequestCallId, responseJson);
      }


    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getPublicKey();
    const urlParams = new URLSearchParams(window.location.search);
    const redirectStatus = urlParams.get('redirect_status');
    if (redirectStatus) {
      this.setState({
        redirectStatus: redirectStatus
      });
    }
  }

  apiFailureCallBacks = (apiRequestCallId: string, responseJson: InvalidResponseType) => {
    if (apiRequestCallId == this.createPaymentIntentApiCallId) {
      this.showAlert("Error", responseJson.error);
    }
  }

  apiSuccessCallBacks = async (apiRequestCallId: string, responseJson: ValidResponseType) => {
    if (apiRequestCallId == this.createPaymentIntentApiCallId) {
      this.setState({ clientSecret: responseJson.payment_intent.client_secret }, () => {
        this.setState({
          clientSecret: responseJson.payment_intent.client_secret
        }, () => {
          this.setState({
            stripForm: true
          });
        });
      })
    }
    if (apiRequestCallId == this.getPublicKeyApiCallId) {
      this.setState({
        stripPublicKey: responseJson.stripe_publishable_key
      });
    }
    if (apiRequestCallId == this.postCreateAccountApiCallId) {
      this.showAlert("Message", "Account Created Successfully");
      this.setState({
        merchantId: responseJson.connected_account.id
      });
    }
    if (apiRequestCallId == this.postAccountOnboardingApiCallId) {
      this.onBoardingResponse(responseJson)
    }
  }
  isValidResponse = (responseJson: ValidResponseType) => {
    return responseJson && (responseJson.payment_intent || responseJson.stripe_publishable_key || responseJson.account_link || responseJson.connected_account);
  };

  isInValidResponse = (responseJson: InvalidResponseType) => {
    return responseJson && responseJson.error;
  };

  apiCall = async (data: APIPayloadType) => {
    const { contentType, method, endPoint, body } = data;
    const token = "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6OSwiZXhwIjoxNzUzMzM3Mzc0LCJ0b2tlbl90eXBlIjoibG9naW4ifQ.1J6m28pCQ86XSNEiGcmZo099ae6DqasYlXMnTlPIt-z2SmbE6wkoNPDfNUz2ckgvudn-qnSAW529OY6GsqSFSA";
    const header = {
      "Content-Type": contentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };


  handleSubmit = async (stripe: Stripe | null, elements: StripeElements | null) => {
    if (!stripe || !elements) {
      return;
    }

    const { error: submitError } = await elements.submit();
    if (submitError) {
      this.showAlert("Error", submitError.message ?? "An unknown error occurred");
      return;
    }

    const { error } = await stripe.confirmPayment({
      elements,
      clientSecret: this.state.clientSecret,
      confirmParams: {
        return_url: `${window.location.origin}/StripeConnectSuccess`,
      },
    });
    if (error) {
      this.showAlert("Error", error.message ?? "An unknown error occurred");
    }
  };
  
  onBoardingResponse = (responseJson: ValidResponseType) => {
    this.showAlert("Message", responseJson.message);
    this.setState({
      urlForOnboarding: responseJson.account_link.url
    });
  }
  createAccount = async () => {
    const emailReg = /^[^\s@]{1,64}@[^\s@]{1,255}\.[^\s@]{2,}$/;
    const errors = {
      blankEmailError: !this.state.email,
      emailError: !emailReg.test(this.state.email),
    };
    if (!emailReg.test(this.state.email)) {
      this.setState({
        emailErrorMsg: "Please enter valid email id"
      });
    }
    if (!this.state.email) {
      this.setState({
        emailErrorMsg: "Please enter email id"
      });
    }
    this.setState(errors);
    const hasErrorsCreateAcount = Object.values(errors).some(error => error);
    if (!hasErrorsCreateAcount) {
      this.postCreateAccountApiCallId = await this.apiCall({
        contentType: configJSON.apiContentType,
        method: configJSON.confirmPaymentMethod,
        endPoint: configJSON.createAccount + this.state.email,
      });
    }
  }

  getOnboardingLink = async () => {
    let apiData = {
      "account": this.state.merchantId,
      "email": this.state.email
    }

    this.postAccountOnboardingApiCallId = await this.apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.confirmPaymentMethod,
      endPoint: configJSON.accountOnboarding,
      body: apiData,
    });
  }

  checkout = () => {
    const errors = {
      blankAmountError: !this.state.amount,
      blankCurrencyError: !this.state.currency.name,
    };
    this.setState(errors);
    const hasErrors = Object.values(errors).some(error => error);
    if (!hasErrors) {
      this.createPaymentIntent()
    }
  }

  handleUserEmailInput = (event: string) => {
    const emailReg = /^[^\s@]{1,64}@[^\s@]{1,255}\.[^\s@]{2,}$/;
    const isValidEmail = emailReg.test(event);
    this.setState({
      email: event
    })
    if (event === "") {
      this.setState({
        emailError: false,
        blankEmailError: true,
        emailErrorMsg: "Please enter email id"
      });
    } else if (isValidEmail === false) {
      this.setState({
        emailError: true,
        blankEmailError: false,
        emailErrorMsg: "Please enter valid email id"
      });
    } else {
      this.setState({
        emailError: false,
        blankEmailError: false,
        emailErrorMsg: ""
      });
    }
  };

  onChangeText = (event: string) => {
    this.setState({
      amount: event
    });
  }

  onChangeCurrency = (currency: RegionType) => {
    this.setState({
      currency: currency,
      region: { name: "", key: "" },
      isOpenCurrency: false
    });
  }

  handleDropdownRegion = () => {
    this.setState(prevState => ({ isOpenRegion: !prevState.isOpenRegion }));
  };

  handleDropdownCurrecny = () => {
    this.setState(prevState => ({ isOpenCurrency: !prevState.isOpenCurrency }));
  };

  handleSelectRegion = (region: RegionType) => {
    this.setState({ region: region, currency: region, isOpenRegion: false });
  };

  createPaymentIntent = async () => {
    let apiData = {
      "amount": this.state.amount,
      "currency": this.state.currency.key,
      "application_fee_amount": 100,
      "account": this.state.merchantId 
    }

    this.createPaymentIntentApiCallId = await this.apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.confirmPaymentMethod,
      endPoint: "bx_block_stripe_integration/payment_intents/create_payment_intent",
      body: apiData,
    });
  };

  getPublicKey = async () => {
    this.getPublicKeyApiCallId = await this.apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.getPublicKey,
      endPoint: "bx_block_stripe_integration/payment_intents/get_publishable_key",
    });
  };

  handleNavigate = (route: string) => {
    const toNavigate = new Message(getName(MessageEnum.NavigationMessage));
    toNavigate.addData(
      getName(MessageEnum.NavigationTargetMessage),
      route
    );
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
  }
  // Customizable Area End
}
