import React, { Component } from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

export type withLoaderProps = {
  displaySpinner: () => void;
  hideLoader: () => void;
  showLoader: () => void;
  history: any;
  // Customizable Area Start
  // Customizable Area End
};

export default function WithLoader(
  OriginalComponent: React.ComponentClass<withLoaderProps>
) {
  return class extends Component {
    // Customizable Area Start
    // Customizable Area End

    state = {
      isLoading: false,
      // Customizable Area Start
      // Customizable Area End
    };

    // Customizable Area Start
    showLoader = () => {
      this.setState({
        isLoading: true,
      });
    };

    displaySpinner = () => {
      this.setState({
        isLoading: true,
      });
    };

    hideLoader = () => {
      this.setState({ isLoading: false });
    };

    render() {
      return (
        <>
          {this.state.isLoading && (
            <Backdrop style={{ zIndex: 9999 }} open={this.state.isLoading}>
              <div
                style={{
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  transform: "translate(-50%, -50%)",
                  color: "goldenrod",
                }}
              >
                <CircularProgress size="5rem" color="primary" />
              </div>
            </Backdrop>
          )}
          <OriginalComponent
            {...({...this.props, history: undefined})}
            showLoader={this.showLoader}
            displaySpinner={this.displaySpinner}
            hideLoader={this.hideLoader}
          />
        </>
      );
    }
  };
  // Customizable Area End
}
