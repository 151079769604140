import React from "react";
import Razorpay6CheckoutController from "./Razorpay6CheckoutController.web";
import { Props } from "./Razorpay6CheckoutController.web";
import Razorpay6CheckoutComponent from "./Razorpay6CheckoutComponent.web";

class Razorpay6Checkout extends Razorpay6CheckoutController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      <Razorpay6CheckoutComponent
        {...this.props}
        handleSuccessResponse={this.handleSuccessResponse}
        data-test-id="checkout"
      />
    );
  }
}

export default Razorpay6Checkout;
