import React from "react";

import {
  Container,
  Box,
  Typography,
  // Customizable Area Start
  Modal,
  Button,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  Paper,
  // Customizable Area End
  CardMedia,
} from "@material-ui/core";

// Customizable Area Start
import {
  createTheme,
  ThemeProvider,
  Theme,
  StylesProvider,
} from "@material-ui/core/styles";
import { imgPasswordVisible } from "./assets";
import { Item } from "./types";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import Razorpayintegration6Controller, {
  Props,
  configJSON,
} from "./Razorpayintegration6Controller.web";

export default class Razorpayintegration6 extends Razorpayintegration6Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <StylesProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Container maxWidth="md">
            <Paper style={webStyle.paper}>
              <TableContainer style={webStyle.tableContainer}>
                <Table aria-label="simple table" stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>{configJSON.idText}</TableCell>
                      <TableCell>{configJSON.totalFeesText}</TableCell>
                      <TableCell>{configJSON.totalItemsText}</TableCell>
                      <TableCell>{configJSON.totalTaxText}</TableCell>
                      <TableCell>{configJSON.customerText}</TableCell>
                      <TableCell align="right">
                        {configJSON.actionText}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.cartOrderList &&
                    this.state.cartOrderList.length !== 0 ? (
                      this.state.cartOrderList.map((item, index) => {
                        const value = item.attributes;
                        const orderItemId: string = item.id;
                        return (
                          <TableRow key={orderItemId}>
                            <TableCell scope="row">{orderItemId}</TableCell>
                            <TableCell>{value.total_fees}</TableCell>
                            <TableCell>{value.total_items}</TableCell>
                            <TableCell>{value.total_tax}</TableCell>
                            <TableCell>
                              {`${value.customer.data.attributes.first_name} ${value.customer.data.attributes.last_name}`}
                            </TableCell>
                            <TableCell align="right">
                              <Button
                                data-test-id={`viewOrderItems-${index}`}
                                variant="text"
                                color="primary"
                                onClick={() => {
                                  this.showOrderApi(
                                    item.attributes.order_items.data,
                                    orderItemId
                                  );
                                }}
                              >
                                <CardMedia
                                  component="img"
                                  height="30px"
                                  width="30px"
                                  image={imgPasswordVisible}
                                  alt="eye"
                                />
                                {configJSON.viewButtonText}
                              </Button>
                              <Button
                                data-test-id={`checkout-${index}`}
                                variant="text"
                                onClick={() => {
                                  this.handleCheckout(item);
                                }}
                              >
                                {configJSON.checkOutButtonTextTwo}
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <Typography style={webStyle.note}>
                        {configJSON.noOrdersToDisplay}
                      </Typography>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>

            <Modal
              open={this.state.isVisible}
              onClose={this.hideModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={webStyle.modal}>
                <Paper style={webStyle.paperContainer}>
                  <TableContainer style={webStyle.tableContainer}>
                    <Table aria-label="simple table" stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell>{configJSON.idText}</TableCell>
                          <TableCell>{configJSON.priceText}</TableCell>
                          <TableCell>{configJSON.quantityText}</TableCell>
                          <TableCell>{configJSON.taxableText}</TableCell>
                          <TableCell>{configJSON.taxableValueText}</TableCell>
                          <TableCell>{configJSON.catalogueText}</TableCell>
                          <TableCell align="right">
                            {configJSON.actionText}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.cartOrderItems &&
                          this.state.cartOrderItems.map(
                            (item: Item, index: number) => {
                              const value = item.attributes;
                              const orderSingleItemId = item.id;
                              return (
                                <TableRow key={orderSingleItemId}>
                                  <TableCell scope="row">
                                    {orderSingleItemId}
                                  </TableCell>
                                  <TableCell>{value.price}</TableCell>
                                  <TableCell>{value.quantity}</TableCell>
                                  <TableCell>
                                    {value?.taxable ? "true" : "false"}
                                  </TableCell>
                                  <TableCell>{value.taxable_value}</TableCell>
                                  <TableCell>
                                    {value.catalogue.data.attributes.name}
                                  </TableCell>
                                  <TableCell align="right">
                                    <Button
                                      data-test-id={`deleteOrderItem-${index}`}
                                      onClick={() => {
                                        this.deleteOrderItem(
                                          this.state.cartOrderId,
                                          parseInt(orderSingleItemId)
                                        );
                                      }}
                                    >
                                      {configJSON.deleteButtonText}
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              );
                            }
                          )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>

                <Box sx={webStyle.closeModalContainer}>
                  <Button
                    data-test-id="closeModal"
                    variant="contained"
                    onClick={this.hideModal}
                  >
                    {configJSON.closeButtonText}
                  </Button>
                </Box>
              </Box>
            </Modal>
          </Container>
        </ThemeProvider>
      </StylesProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  tableContainer: {
    maxHeight: 440,
  },
  paper: {
    width: "100%",
    overflow: "hidden",
  },
  modal: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  },
  modalAddButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  closeModalContainer: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "10px 0px",
  },
  paperContainer: {
    width: "100%",
    overflow: "hidden",
  },
  note: {
    width: "100%" as "100%",
    textAlign: "center" as "center",
  },
};
// Customizable Area End
