import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import { withLoaderProps } from "./ProminLoader.web";
import axios from "axios";
// Customizable Area Start
import { message as MESSAGE } from "antd";
import { History } from "history";
// Customizable Area End

export const configJSON = require("./config");
export const baseURLconfig = require("../../../framework/src/config");

// Customizable Area Start
interface GradeListDataType {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
  };
}

interface AgeListDataType {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
  };
}

interface AllQuestionData {
  attributes: {
    question_no: string;
    question: string;
    options: { data: [{ attributes: { possible_answer: string } }] };
    link_to_detail: string;
    correct_answer: string;
  };
}

interface QuestionBankListData {
  attributes: {
    id: number;
    name: string;
  };
}

interface ValuesData {
  grade: "";
  age: "";
}
// Customizable Area End

export type Props = RouterProps &
  withLoaderProps & {
    // Customizable Area Start
    history: History;
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  downloadChangeModal: boolean;
  quetionSeeModal: boolean;
  quetionBankList: Array<QuestionBankListData>;
  gradeList: Array<GradeListDataType>;
  ageList: Array<AgeListDataType>;
  allQuetionDataForShowButton: Array<AllQuestionData>;
  token: string;
  allData: {
    meta: {
      total_pages: number;
    };
  };
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class QuestionBankController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getQuetionBankListApiCallId!: string;
  getGradeListApiCallId!: string;
  getAgeListApiCallId!: string;
  getQuestionBankListAPICallId!: string;
  getDownloadTemplateAPICallId!: string;
  postUploadTemplateAPICallId!: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.CountryCodeMessage),
    ];

    // Customizable Area Start
    this.state = {
      downloadChangeModal: false,
      quetionSeeModal: false,
      quetionBankList: [],
      gradeList: [],
      ageList: [],
      token: "",
      allQuetionDataForShowButton: [],
      allData: {
        meta: {
          total_pages: 0,
        },
      },
    };
    // Customizable Area End
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  async componentDidMount() {
    const token = (await localStorage.getItem("token")) || "";
    this.setState({ token: token });
    this.getQuetionBankList();
    this.getAgeList();
    this.getGradeList();
  }

  apiCall = async (data: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    type?: string;
  }) => {
    const { contentType, method, endPoint, body, type } = data;
    const header = {
      "Content-Type": contentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        type === "" ? JSON.stringify(body) : body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.status === 500) {
        MESSAGE.error(`${responseJson.error}. Please try again later.`, 4);
        return;
      }
      if (apiRequestCallId === this.getDownloadTemplateAPICallId) {
        this.setState({
          downloadChangeModal: false,
        });
        let fileUrl = `${baseURLconfig.baseURL}${responseJson.meta.url}`;
        this.handleDownloadFiles(fileUrl);
      }
      if (responseJson.data) {
        switch (apiRequestCallId) {
          case this.getQuetionBankListApiCallId:
            this.props.hideLoader();
            this.setState({
              quetionBankList: responseJson.data,
            });
            break;
          case this.getGradeListApiCallId:
            this.setState({
              gradeList: responseJson.data,
            });
            break;
          case this.getAgeListApiCallId:
            this.setState({
              ageList: responseJson.data,
            });
            break;
          case this.getQuestionBankListAPICallId:
            this.props.hideLoader();
            this.setState({
              quetionSeeModal: true,
              allQuetionDataForShowButton: responseJson.data,
              allData: responseJson,
            });
            break;
          case this.postUploadTemplateAPICallId:
            this.props.hideLoader();
            break;
          default:
            break;
        }
      } else if (responseJson && responseJson.errors) {
        switch (apiRequestCallId) {
          case this.getQuetionBankListApiCallId:
          case this.getGradeListApiCallId:
          case this.getAgeListApiCallId:
          case this.getQuestionBankListAPICallId:
          case this.getDownloadTemplateAPICallId:
            MESSAGE.error(`${responseJson.errors[0]}.`, 4);
            break;
          case this.postUploadTemplateAPICallId:
            this.props.hideLoader();
            MESSAGE.error(`${responseJson.errors[0]}.`, 4);
            break;
          default:
            break;
        }
      }
    }
  }

  getQuetionBankList = async () => {
    this.props.showLoader();
    this.getQuetionBankListApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.questionBankListAPiEndPoint,
    });
  };

  getGradeList = async () => {
    this.getGradeListApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.gradeAPiEndPoint,
    });
  };

  getAgeList = async () => {
    this.getAgeListApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.ageListAPiEndPoint,
    });
  };

  getallQuestion = async (page: number) => {
    this.getQuestionBankListAPICallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.questionBankObjectListAPiEndPoint}${page}`,
    });
  };

  downloadTemplate = async (values: { grade: ""; age: "" }) => {
    this.props.showLoader();
    this.getDownloadTemplateAPICallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.downloadTemplateAPiEndPoint}${values.grade}&age_group_id=${values.age}`,
    });
  };

  handleDownloadFiles = async (data: string) => {
    axios({
      url: data,
      method: "GET",
      responseType: "blob",
      headers: {
        token: `${this.state.token}`,
      },
    }).then((response) => {
      const urll = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = urll;
      link.setAttribute("download", "sample_file.xlsx");
      document.body.appendChild(link);
      link.click();
      this.props.hideLoader();
    });
  };

  handlePageClick = (events: { selected: number }) => {};

  onFinish = async (values: ValuesData) => {
    if (this.validateForm()) {
      this.downloadTemplate(values);
    }
  };

  validateForm = () => {
    let isValid = true;
    return isValid;
  };

  downloadChangeModal = () => {
    this.setState({
      downloadChangeModal: true,
    });
  };

  downloadChangeModalCancel = () => {
    this.setState({
      downloadChangeModal: false,
    });
  };

  quetionSeeModal = (quetionBankListItem: { attributes: { id: number } }) => {
    this.props.showLoader();
    setTimeout(() => {
      this.getallQuestion(quetionBankListItem.attributes.id);
    }, 1500);
  };

  quetionSeeModalCancel = () => {
    this.setState({
      quetionSeeModal: false,
    });
  };

  fileHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.showLoader();
    let file = event.target.files?.[0] || null;
    const fileDetailes = file as unknown as Blob;
    let formdata = new FormData();
    formdata.append("name", "file.csv");
    formdata.append("file", fileDetailes);
    formdata.append("chapter", "Maths");
    this.postUploadTemplateAPICallId = await this.apiCall({
      method: configJSON.exampleAPiMethod,
      endPoint: `${configJSON.uploadTemplateAPiEndPoint}`,
      body: formdata,
      type: "formData",
    });
  };

  onChangeCall = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.fileHandler(event);
  };
}

// Customizable Area End
