import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
export interface APIPayloadType {
  contentType: string;
  method: string;
  endPoint: string;
  body?: object;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  redirectStatus: string;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class StripeConnectSuccessController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  postAnalyticsPaymentIntentApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start\
      redirectStatus: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const redirectStatus = urlParams.get('redirect_status');
    const paymentIntent = urlParams.get('payment_intent');

    if (redirectStatus) {
      this.setState({
        redirectStatus: redirectStatus
      }, () => {
        if (this.state.redirectStatus === "succeeded") {
          this.getAnalyticsPayment(paymentIntent);
        }
      });
    }
  }

  handleNavigate = (route: string) => {
    const toNavigate = new Message(getName(MessageEnum.NavigationMessage));
    toNavigate.addData(
      getName(MessageEnum.NavigationTargetMessage),
      route
    );
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
  }

  getAnalyticsPayment = async (paymentIntent: string | null) => {
    this.postAnalyticsPaymentIntentApiCallId = await this.apiCallSuccess({
      contentType: configJSON.apiContentType,
      method: configJSON.getMethod,
      endPoint: `bx_block_stripe_integration/log_payment_intent/${paymentIntent}`,
    });
  };

  apiCallSuccess = async (data: APIPayloadType) => {
    const { contentType, method, endPoint } = data;
    const token = "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6OSwiZXhwIjoxNzUzMzM3Mzc0LCJ0b2tlbl90eXBlIjoibG9naW4ifQ.1J6m28pCQ86XSNEiGcmZo099ae6DqasYlXMnTlPIt-z2SmbE6wkoNPDfNUz2ckgvudn-qnSAW529OY6GsqSFSA";
    const header = {
      "Content-Type": contentType,
      token,
    };
    const requestMessageSuccess = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageSuccess.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessageSuccess.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessageSuccess.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessageSuccess.id, requestMessageSuccess);
    return requestMessageSuccess.messageId;
  };
  // Customizable Area End
}