import React, { FunctionComponent, useCallback } from "react";
import { useRazorpay, RazorpayOrderOptions } from "react-razorpay";
import { Button, Box, Typography } from "@material-ui/core";
import { IRazorProps, IRazorResp } from "./types";
import { CurrencyCode } from "react-razorpay/dist/constants/currency";
const configJSON = require("./config.js");

const Razorpay6CheckoutComponent: FunctionComponent<IRazorProps> = (props) => {
  const { Razorpay } = useRazorpay();
  const handlePayment = useCallback(async () => {
    const customerDetails =
      props.history.location.state.data.customerData.data.attributes;
    const currency = props.history.location.state.data.item.attributes.currency as CurrencyCode;
    const options: RazorpayOrderOptions = {
      key: `${configJSON.razorKey}`,
      amount: props.history.location.state.data.item.attributes.amount,
      currency: currency,
      name: `${configJSON.projectNameText}`,
      description: `${configJSON.description}`,
      order_id: `${props.history.location.state.data.item.attributes.razorpay_order_id}`,
      handler: (resp: IRazorResp) => {
        props.handleSuccessResponse(resp);
      },
      prefill: {
        name: `${customerDetails.first_name}${" "}${customerDetails.last_name}`,
        email: `${customerDetails.email}`,
        contact: `${customerDetails.full_phone_number}`,
      },
      theme: {
        color: `${configJSON.themeColor}`,
      },
    };

    const rzpay = new Razorpay(options);
    rzpay.open();
  }, [Razorpay]);
  return (
    <Box sx={webStyle.main}>
      <Box sx={webStyle.paper}>
        <Box>
          <Typography variant="h6" style={webStyle.heading}>
            {configJSON.customerDetailsText}
          </Typography>
          <Box sx={webStyle.infoContainer}>
            <Typography variant="body1" style={webStyle.infoSmallHeading}>
              {configJSON.prefillName} :
            </Typography>
            <Typography style={webStyle.infoText} variant="h6">
              {
                props.history.location.state.data.customerData.data.attributes
                  .first_name
              }
            </Typography>
            <Typography style={webStyle.infoText} variant="h6">
              {
                props.history.location.state.data.customerData.data.attributes
                  .last_name
              }
            </Typography>
          </Box>
          <Box sx={webStyle.infoContainer}>
            <Typography variant="body1" style={webStyle.infoSmallHeading}>
              {configJSON.amountText} :{" "}
            </Typography>
            <Typography style={webStyle.infoText} variant="h6">
              {props.history.location.state.data.item.attributes.amount / 100}
            </Typography>
          </Box>
          <Box sx={webStyle.infoContainer}>
            <Typography variant="body1" style={webStyle.infoSmallHeading}>
              {configJSON.phoneNumberText} :{" "}
            </Typography>
            <Typography style={webStyle.infoText} variant="h6">
              {
                props.history.location.state.data.customerData.data.attributes
                  .full_phone_number
              }
            </Typography>
          </Box>
        </Box>
        <Box sx={webStyle.paymentTextContainer}>
          <Typography variant="body1" style={webStyle.paymentText}>
            {configJSON.razorPayNote}
          </Typography>

          <Button
            variant="contained"
            onClick={handlePayment}
            data-test-id="paymentHandle"
          >
            {configJSON.checkOutButtonText}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

const webStyle = {
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    //TODO Fix TypeError: Cannot read properties of undefined
    //backgroundColor: theme.palette.background.paper,
    border: ".2px solid #000",
    //boxShadow: theme.shadows[5],
    //padding: theme.spacing(2, 4, 3),
    borderRadius: "10px",
  },
  heading: {
    color: "rgb(98, 0, 238)",
    textDecoration: "underline",
  },
  infoContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  infoText: {
    textTransform: "capitalize" as "capitalize",
    margin: "0 4px",
  },
  paymentText: {
    margin: "10px 0",
    fontSize: "18px",
    color: "black",
  },
  paymentTextContainer: {
    margin: "50px 0",
    height: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  infoSmallHeading: { width: "130px", textAlign: "left" as "left" },
};

export default Razorpay6CheckoutComponent;
