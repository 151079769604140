import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import storage from "framework/src/StorageProvider.web";
import { handleResponseMessage } from "./helpers/handleResponseMessage";
import { ICheckoutHistory, IRazorResp, IVerifyCallApi } from "./types";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: ICheckoutHistory;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  razorToken: string;
  razorPayVerifyDetails: {
    status: boolean;
    rpay_order_id: string;
    razorpay_payment_id: string;
    razorpay_signature: string;
  };

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Razorpay6CheckoutController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  razorRespApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      razorToken: "",
      razorPayVerifyDetails: {
        status: false,
        rpay_order_id: "",
        razorpay_payment_id: "",
        razorpay_signature: "",
      },
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const requestCallDataId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );

      const responseSuccessWeb = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );

      const responseErrorWeb = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
      );
      if (requestCallDataId === this.razorRespApiCallId) {
        handleResponseMessage({
          responseJson: responseSuccessWeb,
          errorJson: responseErrorWeb,
          onSuccess: () => {
            this.setState({
              razorPayVerifyDetails: responseSuccessWeb?.data.attributes,
            });
          },
          onFail: () => {
            this.showAlert(
              `Error`,
              `${
                responseSuccessWeb.errors
                  ? Object.entries(responseSuccessWeb.errors[0])[0][1]
                  : configJSON.noDataErrorText
              }`,
            );
          },
        });
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    const token = await storage.get("token");
    this.setState({ razorToken: token });
  }
  handleSuccessResponse = (resp: IRazorResp) => {
    const body = resp;
    this.handleDoCallApi({
      contentType: configJSON.apiContentType,
      method: configJSON.putApiMethod,
      endPoint: `${configJSON.verifyRazorPaymentEndPoint}`,
      body: body,
      token: this.state.razorToken,
    });
  };
  handleDoCallApi = (data: IVerifyCallApi) => {
    const { contentType, method, endPoint, body, token } = data;
    const header = {
      "Content-Type": contentType,
      "token": token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.razorRespApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method,
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body),
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  // Customizable Area End
}
